import React from "react";

import { Header, Footer } from "../partials";

interface IProps {
  children: any;
}
const MainLayout = (props: IProps) => {
  const { children } = props;
  return (
    <>
      <Header />
      <div className="body-container">{children}</div>
      <Footer />
    </>
  );
};

export default MainLayout;

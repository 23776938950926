import React from "react";
import { Switch, Route } from "react-router-dom";
import "./App.scss";
import { MainLayout } from "./Layout";
import { Landing } from "./pages";

const App = () => {
  return (
    <>
      <Switch>
        <MainLayout>
          <Route path={"/"} component={Landing} />
        </MainLayout>
      </Switch>
    </>
  );
};

export default App;

import React, { useRef, useState } from "react";
import { ReactComponent as FeatureIcon } from "../assets/images/FeatureIconWithCircle.svg";
import { ReactComponent as Ellipse } from "../assets/images/Ellipse.svg";
import { ReactComponent as Success } from "../assets/images/Success.svg";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Loader from "react-loader-spinner";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-toastify/dist/ReactToastify.css";
import "./Landing.scss";

const validateEmail = (email: string): boolean => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const Landing = () => {
  const ref = useRef<HTMLInputElement>(null);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const onEmailSubmitted = async (): Promise<void> => {
    if (!email || !validateEmail(email)) {
      ref.current?.focus();
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        "https://esquity-coming-soon.herokuapp.com/users/add-list",
        { email },
      );
      ref.current!.value = "";
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (e) {
      toast.error(
        "An error occured and we could not complete your request, please try again.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        },
      );
    }
    setLoading(false);
  };

  return (
    <>
      <ToastContainer />
      <section className="introducing-esquity">
        <div className="container">
          <Success className="success" />
          <button className="btn gradient-button">
            <p className="gradient-text">Introducing Esquity</p>
          </button>
          <h1 className="title">
            We Are{" "}
            <span>
              <Ellipse className="ellipse" />
              Including
            </span>{" "}
            The Communities And <br /> Democratizing The Decision To Fund
            Startups In Africa
          </h1>
          <p className="subtitle">
            Esquity is a digital market place where individuals like you and I
            (Non-accredited investors) can co-invest alongside Venture
            Capitalist, Angels and Accredited investors, while owning a piece in
            start-ups we believe in.
          </p>
          <div className="notify">
            <p>Be notified soon as Esquity launches</p>
            <span className="input-button">
              <input
                ref={ref}
                placeholder="Your email address"
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                disabled={loading}
                className="btn btn-primary"
                onClick={onEmailSubmitted}>
                {loading ? (
                  <Loader
                    type="Circles"
                    color="#FFFFFF"
                    height={20}
                    width={20}
                  />
                ) : (
                  "Notify Me"
                )}
              </button>
            </span>
          </div>
        </div>
      </section>

      <section className="how-it-works">
        <div className="container">
          <p className="how-it-works-subtitle">HOW IT WORKS</p>
          <p className="how-it-works-title">Getting started is easy</p>
          <div className="investors-entrepreneurs">
            <div className="left">
              <p className="how-it-works-subtitle">For Investors</p>
              <ul className="how-it-works-list">
                <li className="list">
                  <div className="icon">
                    <FeatureIcon />
                  </div>
                  <div className="group">
                    <p className="group-title">Sign Up</p>
                    <p className="group-subtitle">
                      Get started on the Esquity platform by creating your SSO
                      account and creating a profile.
                    </p>
                  </div>
                </li>
                <li className="list">
                  <div className="icon">
                    <FeatureIcon />
                  </div>
                  <div className="group">
                    <p className="group-title">Browse Companies</p>
                    <p className="group-subtitle">
                      Search through thousands of companies available on
                      Esquity. You can narrow your search based on sectors and
                      other choices.
                    </p>
                  </div>
                </li>
                <li className="list">
                  <div className="icon">
                    <FeatureIcon />
                  </div>
                  <div className="group">
                    <p className="group-title">Invest</p>
                    <p className="group-subtitle">
                      After making a choice on company(s) to invest in, go
                      through the investment terms, fill the required forms and
                      and pay your investment amount.{" "}
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="right">
              <p className="how-it-works-subtitle">For Entrepreneurs</p>
              <ul className="how-it-works-list">
                <li className="list">
                  <div className="icon">
                    <FeatureIcon />
                  </div>
                  <div className="group">
                    <p className="group-title">Sign Up</p>
                    <p className="group-subtitle">
                      Get started on the Esquity platform by creating your SSO
                      account and creating a profile.
                    </p>
                  </div>
                </li>
                <li className="list">
                  <div className="icon">
                    <FeatureIcon />
                  </div>
                  <div className="group">
                    <p className="group-title">Apply to raise</p>
                    <p className="group-subtitle">
                      On deciding to raise funds for your company with Esquity,
                      fill and submit the apply to raise application form.
                    </p>
                  </div>
                </li>
                <li className="list">
                  <div className="icon">
                    <FeatureIcon />
                  </div>
                  <div className="group">
                    <p className="group-title">Wait for feedback</p>
                    <p className="group-subtitle">
                      After submitting your application, give us some time to
                      review and get back to you. After which you can continue
                      your raise campaign.{" "}
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Landing;

import React from "react";
import "./Header.scss";
import { ReactComponent as Twitter } from "../assets/images/Twitter.svg";
import { ReactComponent as Facebook } from "../assets/images/Facebook.svg";
import { ReactComponent as Instagram } from "../assets/images/Instagram.svg";
import { ReactComponent as LinkedIn } from "../assets/images/linkedin.svg";
import { ReactComponent as Logo } from "../assets/images/Logo.svg";

const Header = () => {
  return (
    <header className="header">
      <nav>
        <div className="logo">
          <Logo />
        </div>
        <ul className="nav-links">
          <li className="link instagram">
            <a
              target="_blank"
              href="https://instagram.com/esquity_crowdfunding?utm_medium=copy_link"
              rel="noopener noreferrer">
              <Instagram />
            </a>
          </li>
          <li className="link">
            <a
              target="_blank"
              href="https://www.facebook.com/Esquity-108578954719736/"
              rel="noopener noreferrer">
              <Facebook />
            </a>
          </li>
          <li className="link">
            <a
              target="_blank"
              href="https://twitter.com/_Esquity"
              rel="noopener noreferrer">
              <Twitter />
            </a>
          </li>
          <li className="link linkedin">
            <a
              target="_blank"
              href="https://www.linkedin.com/company/esquity%C2%AE/"
              rel="noopener noreferrer">
              <LinkedIn />
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;

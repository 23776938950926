import React from "react";
import "./Footer.scss";
import { ReactComponent as Twitter } from "../assets/images/Twitter.svg";
import { ReactComponent as Instagram } from "../assets/images/Instagram.svg";
import { ReactComponent as Facebook } from "../assets/images/Facebook.svg";
import { ReactComponent as FooterLogo } from "../assets/images/FooterLogo.svg";
import { ReactComponent as LinkedIn } from "../assets/images/linkedin.svg";
import { ReactComponent as White } from "../assets/images/White.svg";

const Footer = () => {
  return (
    <footer className="footer">
      <White className="leaf bottom-leaf" />
      <div className="container">
        <div className="top">
          <div className="esquity">
            <div className="logo">
              <FooterLogo />
            </div>
            <div className="text">
              Concordat Esquity Ltd is committed to regulated equity
              crowdfunding across Africa to drive Africapitalism
            </div>
            <div className="socials">
              <ul className="nav-links">
                <li className="link">
                  <a
                    href="https://instagram.com/esquity_crowdfunding?utm_medium=copy_link"
                    target="_blank"
                    rel="noopener noreferrer">
                    <Instagram className="social-icon" />
                  </a>
                </li>
                <li className="link">
                  <a
                    href="https://www.facebook.com/Esquity-108578954719736/"
                    target="_blank"
                    rel="noopener noreferrer">
                    <Facebook className="social-icon" />
                  </a>
                </li>
                <li className="link">
                  <a
                    href="https://twitter.com/_Esquity"
                    target="_blank"
                    rel="noopener noreferrer">
                    <Twitter className="social-icon" />
                  </a>
                </li>
                <li className="link">
                  <a
                    href="https://www.linkedin.com/company/esquity%C2%AE/"
                    target="_blank"
                    rel="noopener noreferrer">
                    <LinkedIn className="social-icon" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="linkss">
            <div className="investors">
              <p className="title">Investors</p>
              <ul className="links">
                <li className="link">Why Invest</li>
                <li className="link">How it Works</li>
                <li className="link">FAQ</li>
              </ul>
            </div>
            <div className="entrepreneur">
              <p className="title">Entrepreneurs</p>
              <ul className="links">
                <li className="link">Why Raise</li>
                <li className="link">Pitch</li>
                <li className="link">FAQ</li>
              </ul>
            </div>
            <div className="company">
              <p className="title">Company</p>
              <ul className="links">
                <li className="link">About</li>
                <li className="link">Blog</li>
                <li className="link">Contact</li>
                <li className="link">
                  Careers<span>We're hiring</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="horizontal-line" />
        <div className="bottom">
          <p className="text">
            Esquity carries out due diligence on all Prospective issuers on the
            Esquity platform. Investors are advised to Independently evaluate
            listed companies to prioritize their investments. Investing in
            startups are often risky. Maintaining a diversified investment
            porfolio is advised at every given time.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
